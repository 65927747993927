<template>
  <div>
    <el-page-header @back="dynamic" content="新增加油记录" style="margin-bottom: 15px;">
    </el-page-header>
    <el-card>
<!--      <div slot="header" style="text-align: center; font-size: 21px; ">-->
<!--        <B>新 增 加 油 记 录</B>-->
<!--      </div>-->
      <div style="padding-left: 25%">
        <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="车型">
            <el-select v-model="ruleForm.carId" placeholder="请选择">
              <el-option
                  v-for="item in carData"
                  :key="item.carId"
                  :label="item.carNum"
                  :value="item.carId">
                <span style="float: left">{{ item.carNum }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.carName }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item  label="卡号">
            <el-select v-model="ruleForm.cardId" placeholder="请选择" @change="selectCag">
              <el-option
                  v-for="item in cardData"
                  :key="item.cardId"
                  :label="item.cardName"
                  :value="item.cardId">
                <span style="float: left">{{ item.cardName }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.cardNum }}</span>
              </el-option>
            </el-select>
            <span style="color: red; padding-left: 12px"> 余额： </span>
            <el-tag type="success">{{ ruleForm.balance }}</el-tag>
          </el-form-item>
          <el-form-item  label="使用人">
            <el-select v-model="ruleForm.empId" placeholder="请选择">
              <el-option
                  v-for="item in empData"
                  :key="item.empId"
                  :label="item.empName"
                  :value="item.empId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <div class="block">
              <el-date-picker
                  v-model="ruleForm.oilDate"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="加油量">
            <el-input v-model="ruleForm.oil" style="width: 220px"></el-input>
          </el-form-item>
          <el-form-item label="单价">
            <el-input v-model="ruleForm.price" style="width: 220px"></el-input>
          </el-form-item>
          <el-form-item label="金额">
            <el-input v-model="ruleForm.total" style="width: 220px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

  </div>
</template>

<script>
export default {
  name: "insertOil",
  data() {
    return {
      ruleForm: {
        carId: '',
        cardId: '',
        empId: '',
        oilDate: '',
        oil: '',
        price: '',
        total: '',
        balance: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      carData: [],
      cardData: [],
      empData: [],
      oilDate: ''
    };
  },
  mounted() {
    this.getCarData()
    this.getCardData()
    this.getEmpData()
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            method: 'post',
            url: '/api/oil/insertOil',
            data: this.ruleForm
          }).then((res)=>{
            alert(res.data.msg)
            if (res.data.status) this.ruleForm = {}
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm = {}
    },
    getCarData() {
      this.$http({
        method: 'get',
        url: '/api/car/getCarList',
        data: []
      }).then((res)=>{
        this.carData = res.data.data
      })
    },
    getCardData() {
      this.$http({
        method: 'get',
        url: '/api/card/getCardList',
        data: []
      }).then((res)=>{
        this.cardData = res.data.data
      })
    },
    getEmpData() {
      this.$http({
        method: 'get',
        url: '/api/emp/getEmpList',
        data: []
      }).then((res)=>{
        this.empData = res.data.data
      })
    },
    dynamic() {
      this.$router.push("dynamic")
    },
    selectCag(cardId) {
      var cardIdobj = this.cardData.find((item) => {
        return item.cardId = cardId
      })
      this.ruleForm.balance = cardIdobj.balance
    }
  }
}
</script>

<style scoped>

</style>